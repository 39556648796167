import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserContextProvider } from './UserContextProvider';

const queryClient = new QueryClient();

const RootElement = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>{children}</UserContextProvider>
    </QueryClientProvider>
  );
};

export default RootElement;
