import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useState } from 'react';
import { userQuery } from '../queries';

type User = {
  userName: string;
  isContributor: boolean;
  initiativesLed: string[];
};

type UserContextType = {
  user: User | null;
  setUser: (user: User) => void;
  error: unknown | null;
};

const UserContext = createContext<null | UserContextType>(null);

export const useUserContext = () => {
  const userCxt = useContext(UserContext);

  if (!userCxt) {
    throw new Error(
      'The useUserContext hook can only be used within UserProvider.',
    );
  }

  return userCxt;
};

export const UserContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<unknown | null>(null);

  const queryData = useQuery({
    ...userQuery,
    onSuccess(data) {
      // console.log('data', data);
      // add more checking
      setUser(data);
    },
    onError(err) {
      // try {
      //   const errJson = await err.json();
      //   setError(errJson);
      // } catch (e) {
      //   setError(e);
      // }
    },
    retry: false,
    enabled: user === null,
  });

  return (
    <UserContext.Provider
      value={{ user: user, setUser: setUser, error: queryData.error }}
    >
      {children}
    </UserContext.Provider>
  );
};
