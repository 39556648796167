import { API_URL, isLoginEnable } from './constants';

export const userQuery = {
  queryKey: ['me'],
  queryFn: () =>
    isLoginEnable
      ? fetch(`${API_URL}/i/me`, { credentials: 'include' }).then((res) => {
          return res.json();
        })
      : Promise.resolve({}),
};
